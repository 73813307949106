/*
    Last Modified : 20.09.2024
    Last Modified By : Hariprakash Karthikeyan
*/

// modules
import React, { Component } from "react";
import cx from 'classnames';
import axios from "axios";
// import ReactToPdf from 'react-to-pdf'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

// utils
import { DAYS_FILTERS, getChartData, getErrorChartData, getInitialReportFilters, getDetailedReportData, handleDetailedReportDataError, loadCharts, REPORTS_INITIAL_STATE, getFromAndToDate, downloadTableAsCSV, getUsersbyCreditTransaction, getTasksbyCreditTransaction, getModelsbyCreditTransaction, getDailyTrend, getReportData, ADVANCED_FILTERS_STATES } from "../UserManagement.utils";
import { REPORT_DATA, DETAILED_REPORT_DATA } from "../../../urls/Urls";
import { getDateFromInput, /* getDownloadFileNameWithTime, */ getWesternNumber, isEmpty, isEmptyList, isEmptyObject, isValidTextString } from "../../../utils/UtilityFunctions";
import { BUTTON_TEXTS, SEPARATORS } from "../../../strings/Strings";
import { BUTTON_TYPES } from "../../../utils/ButtonConstants";

// classes
import classes from '../UserManagement.module.css';
import globalClasses from '../../../App.module.css'

// components
import Loader from "../../../components/loader/Loader";
import NoData from "../../../components/no_data/NoData";
import ErrorPage from "../../../components/error_page/ErrorPage";
import SortingTable from "../../../components/sorting_table/SortingTable";

import PieChart from "../../../components/charts/PieChart";
import HybridChart from "../../../components/charts/HybridChart";

// images
// import REPORT from '../../../assets/images/reports/report.png';
// import EXPORT from '../../../assets/images/reports/export.png';
import REPORT_USERS from '../../../assets/images/reports/user.png';
import REPORT_CREDITS from '../../../assets/images/reports/credits.png';
import REPORT_TRANSACTIONS from '../../../assets/images/reports/transaction.png';
import REPORT_ACCOUNTS from '../../../assets/images/reports/service.png';
import { REPORT_STRINGS } from "../../../strings/UserManagement";
import SelectField from "../../../components/ui_elements/form_fields/select_input/SelectField";
import { getCognitoUserAccessToken } from "../../sign_up/Cognito.utils";
import { SORT_TABLE_VALUES, SUBSCRIPTION_PRODUCTS } from "../../../utils/Constants";
import { getTableTimeData } from "../../../utils/UIUtilityFunctions";
import Button from "../../../components/ui_elements/button/ButtonNew";

class ReportsPane extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...REPORTS_INITIAL_STATE
        }
        this.reportRef = React.createRef()
    }

    // get the reports data
    getData = () => {
        let { selectedDaysFilter } = this.state;
        this.setState({
            ...loadCharts()
        }, () => {
            let url = REPORT_DATA + '?days=' + (DAYS_FILTERS.indexOf(selectedDaysFilter) + 1)
            let data = {
                account_id: this.props.accountID
            }
            axios.post(url, data,
                {
                    headers:{
                        Authorization: "Bearer "+getCognitoUserAccessToken()
                    }
                })
                .then(res => {
                    this.setState({
                        ...getChartData(res.data)
                    })
                })
                .then(res => {
                    this.getInitialModelNames();
                })
                .catch(err => {
                    this.setState({
                        ...getErrorChartData(err.message)
                    })
                })
        })
    }

    // get the reports Detailed data
    getDetailedReport = () => {
        let { selectedDaysFilter } = this.state;

        let url = DETAILED_REPORT_DATA + "?account_id=" + this.props.accountID + '&days=' + (DAYS_FILTERS.indexOf(selectedDaysFilter) + 1)

        axios.get(url,
            {
                headers:{
                    Authorization: "Bearer "+getCognitoUserAccessToken()
                }
            })
            .then(res => {
                this.setState({
                    ...getDetailedReportData(res.data)
                })
            })
            .catch(err => {
                this.setState({
                    ...handleDetailedReportDataError(err.message)
                })
            })

    }

    // get the reports Detailed data
    getfilteredReport = () => {
        const { metaKeyFilter, metaValueFilter, fromdateFilter, toDateFilter } = this.state;

        // checking the values are empty or not
        let isErrorMetaValue = isValidTextString(metaValueFilter.join(SEPARATORS.COMMA), !isEmpty(metaKeyFilter)).error;

        if(!isErrorMetaValue){
            // forming the get method url for query params
            let url = DETAILED_REPORT_DATA + "?account_id=" + this.props.accountID + "&filter_type=2";
            if(!isEmpty(metaKeyFilter) && !isEmptyList(metaValueFilter)){
                url += "&meta_key=" + metaKeyFilter + "&meta_value=" + metaValueFilter.join(SEPARATORS.COMMA)
            }
            if(!isEmpty(fromdateFilter)){
                url += "&start_date=" + getDateFromInput(fromdateFilter, true)
            }
            if(!isEmpty(toDateFilter)){
                url += "&end_date=" + getDateFromInput(toDateFilter, true)
            }

            // calling api after forming url
            this.setState({
                isDetailedViewReportLoading: true
            }, () => {
                axios.get(url,
                    {
                        headers:{
                            Authorization: "Bearer "+getCognitoUserAccessToken()
                        }
                    })
                    .then(res => {
                        this.setState({
                            detailedReportData: getReportData(res.data),
                            isDetailedViewReportLoading: false,
                            isDetailedViewReportError : false
                        })
                    })
                    .catch(err => {
                        this.setState({
                            ...handleDetailedReportDataError(err.message)
                        })
                    })
                    .finally(() => {
                        this.setState({
                            isSearchError : false,
                            isErrorMetaValue
                        })
                    })
            })
        } else {
            // setting error scenarios
            this.setState({
                isSearchError : true,
                isErrorMetaValue
            })
        }
    }

    //set selected product id for report
    setSelectedProductType(index){
        let selectedProduct=index
            this.setState({
                selectedProduct
            })
    }

    // window resize handler
    onWindowResize = () => {
        let screenWidth = document.body.clientWidth;
        this.setState({
            screenWidth
        })
    }

    // setting loader and get data on rendering
    componentDidMount = () => {
        let { isActiveTab } = this.props;
        if (isActiveTab) {
            this.setState({
                isDetailedViewReport : true,
                isDetailedViewReportLoading : true
            }, () => {
                this.getDetailedReport();
                this.onWindowResize();
            })
        }
        window.addEventListener('resize', this.onWindowResize);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.isActiveTab !== this.props.isActiveTab) {
            let { isActiveTab } = this.props;
            if (isActiveTab) {
                this.setState({
                    isDetailedViewReport : true,
                    isDetailedViewReportLoading : true
                }, () => {
                    this.getDetailedReport();
                    this.onWindowResize();
                })
            }
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.onWindowResize);
    }

    getInitialModelNames = () => {
        let { selectedDaysFilter } = this.state;
        let url = REPORT_DATA + '?days=' + (DAYS_FILTERS.indexOf(selectedDaysFilter) + 1) + '&graphfilter=3'
        let data = {
            account_id: this.props.accountID,
            model_name: REPORT_STRINGS.ALL_VALUE.toLowerCase(),
            model_limit: REPORT_STRINGS.ALL_VALUE.toLowerCase()
        }
        axios.post(url, data,
            {
                headers:{
                    Authorization: "Bearer "+getCognitoUserAccessToken()
                }
            })
            .then(res => {
                let initialModelNames = [];
                if (!isEmptyList(res.data.credits_transaction_by_model)) {
                    res.data.credits_transaction_by_model.map(item => {
                        initialModelNames.push(item.model_name)
                        return null
                    })
                }
                this.setState({
                    initialModelNames: initialModelNames.sort()
                })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    // on change task credit and transaction filter
    onChangeDailyTrend = (tier, taskname, modelname, username) => {
        let { selectedDaysFilter } = this.state;
        let url = REPORT_DATA + '?days=' + (DAYS_FILTERS.indexOf(selectedDaysFilter) + 1) + '&graphfilter=4'
        let data = {
            account_id: this.props.accountID,
            model_name: modelname.toLowerCase(),
            user_email: username.toLowerCase(),
            task_name: taskname.toLowerCase(),
            model_tier: tier.toLowerCase() === REPORT_STRINGS.ALL_VALUE.toLowerCase() ? tier.toLowerCase() : tier.toLowerCase().split(' ')[1]
        }
        this.setState({
            dailyTrendLoading: true,
            searchedTier: tier,
            searchedTrendModelName: modelname,
            searchedTrendTaskName: taskname,
            searchedTrendUserName: username
        }, () => {
            let dailyTrendData = {};
            axios.post(url, data,
                {
                    headers:{
                        Authorization: "Bearer "+getCognitoUserAccessToken()
                    }
                })
                .then(res => {
                    console.log('graphfilter2', res.data)
                    if (!isEmptyList(res.data.daily_trend)) {
                        console.log('graphfilter2', res.data.daily_trend)
                        dailyTrendData = {
                            ...getDailyTrend(res.data.daily_trend)
                        }
                    }
                    this.setState({
                        dailyTrendData,
                        dailyTrendLoading: false
                    })
                })
                .catch(err => {
                    console.log('graphfilter2', err.message)
                    this.setState({
                        dailyTrendData,
                        dailyTrendLoading: false
                    })
                })
        })
    }

    // on change task credit and transaction filter
    onChangeModelsCreditTransaction = (modellimit, modelname) => {
        let { selectedDaysFilter } = this.state;
        let url = REPORT_DATA + '?days=' + (DAYS_FILTERS.indexOf(selectedDaysFilter) + 1) + '&graphfilter=3'
        let data = {
            account_id: this.props.accountID,
            model_name: modelname.toLowerCase(),
            model_limit: modellimit.toLowerCase()
        }
        this.setState({
            modelByCreditTransactionLoading: true,
            searchedModelLimit: modellimit,
            searchedModelName: modelname
        }, () => {
            let modelByCreditTransactionData = {};
            axios.post(url, data,
                {
                    headers:{
                        Authorization: "Bearer "+getCognitoUserAccessToken()
                    }
                })
                .then(res => {
                    if (!isEmptyList(res.data.credits_transaction_by_model)) {
                        modelByCreditTransactionData = {
                            ...getModelsbyCreditTransaction(res.data.credits_transaction_by_model)
                        }
                    }
                    this.setState({
                        modelByCreditTransactionData,
                        modelByCreditTransactionLoading: false
                    })
                })
                .catch(err => {
                    console.log('graphfilter2', err.message)
                    this.setState({
                        modelByCreditTransactionData,
                        modelByCreditTransactionLoading: false
                    })
                })
        })
    }

    // on change task credit and transaction filter
    onChangeTasksCreditTransaction = (taskname) => {
        let { selectedDaysFilter } = this.state;
        let url = REPORT_DATA + '?days=' + (DAYS_FILTERS.indexOf(selectedDaysFilter) + 1) + '&graphfilter=2'
        let data = {
            account_id: this.props.accountID,
            task_name: taskname.toLowerCase()
        }
        this.setState({
            taskByCreditTransactionLoading: true,
            searchedTaskName: taskname
        }, () => {
            let taskByCreditTransactionData = {};
            axios.post(url, data,
                {
                    headers:{
                        Authorization: "Bearer "+getCognitoUserAccessToken()
                    }
                })
                .then(res => {
                    if (!isEmptyList(res.data.credits_transaction_by_task)) {
                        taskByCreditTransactionData = {
                            ...getTasksbyCreditTransaction(res.data.credits_transaction_by_task)
                        }
                    }
                    this.setState({
                        taskByCreditTransactionData,
                        taskByCreditTransactionLoading: false
                    })
                })
                .catch(err => {
                    console.log('graphfilter2', err.message)
                    this.setState({
                        taskByCreditTransactionData,
                        taskByCreditTransactionLoading: false
                    })
                })
        })
    }

    // on change users filter
    onChangeUsersCreditTransaction = (username) => {
        let { selectedDaysFilter } = this.state;
        let url = REPORT_DATA + '?days=' + (DAYS_FILTERS.indexOf(selectedDaysFilter) + 1) + '&graphfilter=1'
        let data = {
            account_id: this.props.accountID,
            user_email: username.toLowerCase()
        }
        this.setState({
            userByCreditTransactionLoading: true,
            searchedUserName: username
        }, () => {
            let userByCreditTransactionData = {};
            axios.post(url, data,
                {
                    headers:{
                        Authorization: "Bearer "+getCognitoUserAccessToken()
                    }
                })
                .then(res => {
                    if (!isEmptyList(res.data.credits_transaction_by_user)) {
                        userByCreditTransactionData = {
                            ...getUsersbyCreditTransaction(res.data.credits_transaction_by_user)
                        }
                    }
                    this.setState({
                        userByCreditTransactionData,
                        userByCreditTransactionLoading: false
                    })
                })
                .catch(err => {
                    this.setState({
                        userByCreditTransactionData,
                        userByCreditTransactionLoading: false
                    })
                })
        })
    }

    // on change days filter
    onChangeDayFilter = (option) => {
        this.setState({
            selectedDaysFilter: option,
            isReportChartLoading: !this.state.isDetailedViewReport,
            isDetailedViewReportLoading: this.state.isDetailedViewReport
        }, () => {
            if (this.state.isDetailedViewReport) {
                this.getDetailedReport()
            } else {
                this.getData()
            }
        })
    }

    // on click clear filter check the default filters and update
    onClickClearFilter = (prevState) => {
        let { selectedDaysFilter } = this.state;
        if (selectedDaysFilter !== DAYS_FILTERS[1]) {
            this.setState({
                ...getInitialReportFilters()
            }, () => {
                this.getData()
            })
        }
    }

    // show detailed report view
    onClickDetailedReportView = () => {
        if (this.state.isDetailedViewReport) {
            this.setState({
                isDetailedViewReport: !this.state.isDetailedViewReport
            }, () => {
                this.getData()
            })
        } else {
            this.setState({
                isDetailedViewReport: !this.state.isDetailedViewReport,
                isDetailedViewReportLoading: true
            }, () => {
                this.getDetailedReport()
            })
        }
    }

    // download the reports
    onClickDownloadReport = () => {
        let { isDetailedViewReport, detailedReportData } = this.state;
        let { accountUUID } = this.props
        if (isDetailedViewReport && !isEmptyList(detailedReportData)) {
            downloadTableAsCSV(detailedReportData, [accountUUID,"Reports"].join(SEPARATORS.UNDERSCORE))
        }
    }

    // to handle click event of the advanced filter option button
    onClickAdvancedFilterHandler = () => {
        this.setState({
            isAdvancedFilterEnabled : true
        })
    }

    // to handle click event of the cancel advanced filter option button
    onClickCancelAdvancedFilterHandler = () => {
        this.setState({
            ...ADVANCED_FILTERS_STATES
        }, () => {
            this.setState({
                isDetailedViewReport : true,
                isDetailedViewReportLoading : true
            }, () => {
                this.getDetailedReport();
                this.onWindowResize();
            })
        })
    }

    // to handle change event for the meta keys dropdown
    onChangeMetaKeyFilter = (value) => {
        const { metaValueFilter, isErrorMetaValue } = this.state;
        let updatedMetaValueFilter = metaValueFilter;
        let updatedIsErrorMetaValue = isErrorMetaValue;
        if(isEmpty(value)){
            updatedMetaValueFilter = [];
            updatedIsErrorMetaValue = false;
        }
        this.setState({
            metaKeyFilter : value,
            isErrorMetaKey : false,
            metaValueFilter : updatedMetaValueFilter,
            isErrorMetaValue : updatedIsErrorMetaValue
        });
    }

    // to handle change event for the meta keys dropdown
    onChangeMetaValueFilter = (value) => {
        this.setState({
            metaValueFilter : value,
            isErrorMetaValue : false
        });
    }

    // to handle change event for the from date
    onChangeFromDateFilter = (value) => {
        this.setState({
            fromdateFilter : value
        });
    }

    // to handle change event for the to date
    onChangeToDateFilter = (value) => {
        this.setState({
            toDateFilter : value
        });
    }

    render() {
        let { selectedDaysFilter, isReportsPaneLoading, isReportsPaneError, isDetailedViewReport, detailedReportData, isDetailedViewReportLoading,
            isDetailedViewReportError, isReportChartLoading, usersCount, serviceAccounCount, totalConsumedCredits, totalTransactions,
            tierByCreditData, tierByTransactionData, userByCreditTransactionData, taskByCreditTransactionData,
            modelByCreditTransactionData, dailyTrendData, screenWidth, userByCreditTransactionLoading,
            taskByCreditTransactionLoading, modelByCreditTransactionLoading, dailyTrendLoading,
            initialModelNames, initialTaskNames, initialTiers, initialUserName, searchedModelLimit,
            searchedModelName, searchedTaskName, searchedTier, searchedUserName, searchedTrendModelName,
            searchedTrendTaskName, searchedTrendUserName, isAdvancedFilterEnabled, fromdateFilter, toDateFilter,
            metaKeyFilter, metaValueFilter, transactionMetadata, isErrorFromDate, isErrorMetaKey, isErrorMetaValue,
            isErrorToDate, fromdateErrorText, todateErrorText, isSearchError } = this.state

        let loaderComponent = null;
        // console.log("detailedReportData1",detailedReportData.filter(item => item.leafGroup === (REPORT_STRINGS.IDP_PRODUCTS.find(product => product.ID === this.state.selectedProduct)).PRODUCT))
        let filterComponentsContainer = null;
        let daysFilterComponent = null;
        let fromDateFilterComponent = null;
        let toDateFilterComponent = null;
        let metaKeyFilterComponent = null;
        let metaValueFilterComponent = null;
        let showDetailedViewBtn = null;
        let reportDataContainer = null;

        let [fromDate, toDate] = getFromAndToDate(selectedDaysFilter);

        if (isReportsPaneError) {
            return (
                <NoData
                    mainText={"No Report Data Found"}
                    subText={"We can't find any data for this user"}
                />
            )
        } else {
            // tabpane loader
            if (isReportsPaneLoading) {
                loaderComponent = (
                    <Loader
                        containerClass={classes.Loader}
                    />
                )
            }

            if(!isAdvancedFilterEnabled){
                // days filter component
                daysFilterComponent = (
                    <SelectField
                        containerClass={"w-100"}
                        optionsList={DAYS_FILTERS}
                        value={selectedDaysFilter}
                        onChangeHandler={(value) => this.onChangeDayFilter(value)}
                        disableErrorText={true}
                        menuMaxHeight={150}
                    />
                )

                // filters containers with clear filter and download report buttons
                filterComponentsContainer = (
                    <div className="row d-flex flex-wrap align-items-center">
                        <div className="col-12 col-sm-6 col-md-3 mb-3 mb-sm-0">
                            {daysFilterComponent}
                        </div>
                        {
                            isDetailedViewReport &&
                            <Button
                                id={'Advanced-Filter-Option-Button'} 
                                containerClass={'ms-auto col-6 col-md-4 col-lg-3 col-xl-2 mb-3 mb-sm-0'} 
                                className={"py-1"}
                                label={"Advanced Filter Options"}
                                actionType={BUTTON_TYPES.ACTIONS.SECONDARY} 
                                radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                                borderType={BUTTON_TYPES.BORDER.BORDERED}
                                onClickHandler={this.onClickAdvancedFilterHandler}
                                disabled={isDetailedViewReportLoading}
                            />
                        }
                        {/* <div className="col-12 col-sm-6 col-md-9">
                            <span
                                className={cx(['mx-2', globalClasses.PointerCursor, globalClasses.Font16MediumOrange])}
                                onClick={this.onClickDetailedReportView}
                            >
                                <img className="mx-1 mb-1" src={REPORT} alt={'Report'} title={'Report'} />
                                {isDetailedViewReport ? BUTTON_TEXTS.HIDE_DETAILED_REPORT
                                    : BUTTON_TEXTS.VIEW_DETAILED_REPORT}
                            </span>
                            {
                                !isEmpty(Number(totalTransactions)) &&
                                <>
                                    |
                                    {
                                        !isDetailedViewReport ?
                                            <ReactToPdf
                                                targetRef={this.reportRef}
                                                filename={getDownloadFileNameWithTime([this.props.accountUUID,"Reports"].join(SEPARATORS.UNDERSCORE))}
                                                options={{
                                                    unit: 'px',
                                                    format: [(isEmpty(this.reportRef.current)) ? 3820 : this.reportRef.current.clientHeight,
                                                    (isEmpty(this.reportRef.current)) ? 1116 : this.reportRef.current.clientWidth],
                                                    hotfixes: ["px_scaling"]
                                                }}
                                            >
                                                {
                                                    ({ toPdf }) => (
                                                        <span
                                                            className={cx(['mx-2', globalClasses.PointerCursor, globalClasses.Font16MediumOrange])}
                                                            onClick={toPdf}
                                                        >
                                                            <img className="mx-1 mb-1" src={EXPORT} alt={'Export'} title={'Export'} />
                                                            {BUTTON_TEXTS.EXPORT_PDF}
                                                        </span>
                                                    )
                                                }
                                            </ReactToPdf>
                                            :
                                            <span
                                                className={cx(['mx-2', globalClasses.PointerCursor, globalClasses.Font16MediumOrange])}
                                                onClick={this.onClickDownloadReport}
                                            >
                                                <img className="mx-1 mb-1" src={EXPORT} alt={'Export'} title={'Export'} />
                                                {BUTTON_TEXTS.EXPORT_CSV}
                                            </span>
                                    }
                                </>
                            }
                        </div> */}
                    </div>

                )
            } else {
                
                // from date filter component
                fromDateFilterComponent = (
                    <div className="form-group w-100">
                        <label
                            className={cx([globalClasses.FormLabel])}
                        // htmlFor="profitReportInputCreditValue"
                        >
                            {'From Date'}
                        </label><br/>
                        <DatePicker
                            dateFormat="MM/dd/yyyy"
                            selected={fromdateFilter}
                            onChange={this.onChangeFromDateFilter}
                            wrapperClassName={'w-100'}
                            className={cx(['form-control py-2', isErrorFromDate ?
                                globalClasses.ErrorFormInput : globalClasses.FormInput])}
                            maxDate={isEmpty(toDateFilter) ? new Date() : toDateFilter}
                            placeholderText={"Select From Date"}
                            disabled={isDetailedViewReportLoading}
                        />
                        {isErrorFromDate && <span className={globalClasses.ErrorText}>{fromdateErrorText}</span>}
                    </div>
                )

                // to date filter component
                toDateFilterComponent = (
                    <div className="form-group w-100">
                        <label
                            className={cx([globalClasses.FormLabel])}
                        >
                            {'To Date'}
                        </label><br/>
                        <DatePicker
                            dateFormat="MM/dd/yyyy"
                            selected={toDateFilter}
                            onChange={this.onChangeToDateFilter}
                            wrapperClassName={'w-100'}
                            className={cx(["form-control py-2",isErrorToDate ?
                                globalClasses.ErrorFormInput : globalClasses.FormInput])}
                            minDate={isEmpty(fromdateFilter) ? null : fromdateFilter}
                            maxDate={new Date()}
                            placeholderText={"Select To Date"}
                            disabled={isDetailedViewReportLoading}
                        />
                        {isErrorToDate && <span className={globalClasses.ErrorText}>{todateErrorText}</span>}
                    </div>
                )

                if(!isEmptyList(transactionMetadata?.metaKeys) && !isEmptyObject(transactionMetadata?.metaValues)){
                    // meta key filter component
                    metaKeyFilterComponent = (
                        <SelectField
                            containerClass={"w-100"}
                            optionsList={transactionMetadata?.metaKeys || []}
                            value={metaKeyFilter}
                            label={"Metadata Key"}
                            onChangeHandler={this.onChangeMetaKeyFilter}
                            placeholder={"Select Metadata Key"}
                            validate={isValidTextString}
                            isError= {isSearchError}
                            isFieldError= {isErrorMetaKey}
                            isDisabled={isDetailedViewReportLoading}
                            isClearable={true}
                        />
                    )

                    // meta value filter component
                    metaValueFilterComponent = (
                        <SelectField
                            containerClass={"w-100"}
                            optionsList={transactionMetadata?.metaValues?.[metaKeyFilter] || []}
                            value={metaValueFilter}
                            label={"Metadata Value"}
                            onChangeHandler={this.onChangeMetaValueFilter}
                            placeholder={"Select Metadata Value(s)"}
                            isDisabled={isDetailedViewReportLoading || isEmpty(metaKeyFilter)}
                            selectMultiple={true}
                            isClearable={false}
                            validate={isValidTextString}
                            isError = {isSearchError}
                            isFieldError = {isErrorMetaValue}
                            isMandatory = {!isEmpty(metaKeyFilter)}
                        />
                    )
                }

                // filters containers with clear filter and download report buttons
                filterComponentsContainer = (
                    <div className="row d-flex flex-wrap">
                        <div className="col-12 col-sm-6 col-md-3 mb-3">
                            {fromDateFilterComponent}
                        </div>
                        <div className="col-12 col-sm-6 col-md-3 mb-3">
                            {toDateFilterComponent}
                        </div>
                        <div className="col-12 col-sm-6 col-md-3">
                            {metaKeyFilterComponent}
                        </div>
                        <div className="col-12 col-sm-6 col-md-3">
                            {metaValueFilterComponent}
                        </div>
                        <Button
                            id={'Cancel-Advanced-Filter-Option-Button'} 
                            containerClass={'col-6 col-md-3 col-lg-2'} 
                            className={"py-1"}
                            label={BUTTON_TEXTS.CANCEL}
                            actionType={BUTTON_TYPES.ACTIONS.SECONDARY} 
                            radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                            borderType={BUTTON_TYPES.BORDER.BORDERED}
                            onClickHandler={this.onClickCancelAdvancedFilterHandler}
                            disabled={isDetailedViewReportLoading}
                        />
                        <Button
                            id={'Submit-Advanced-Filter-Option-Button'} 
                            containerClass={'col-6 col-md-3 col-lg-2'} 
                            className={"py-1"}
                            label={BUTTON_TEXTS.SEARCH}
                            actionType={BUTTON_TYPES.ACTIONS.PRIMARY} 
                            radiusType={BUTTON_TYPES.RADIUS.ROUND_RECTANGLE}
                            borderType={BUTTON_TYPES.BORDER.BORDERED}
                            onClickHandler={this.getfilteredReport}
                            disabled={isDetailedViewReportLoading || (isEmpty(metaKeyFilter) && isEmpty(fromdateFilter) && isEmpty(toDateFilter))}
                        />
                    </div>
                )
            }

            if (isReportChartLoading && !isReportsPaneLoading) {
                reportDataContainer = (
                    <Loader />
                )
            } else {
                if (!isDetailedViewReport) {
                    let usersCountComponent = null, serviceAccountCountComponent = null,
                        totalCreditComponent = null, totalTransactionComponent = null,
                        tierByCreditComponent = null, tierByTransactionComponent = null,
                        userByCreditTransactionComponent = null,
                        taskByCreditTransactionComponent = null,
                        modelByCreditTransactionComponent = null,
                        dailyTrendComponent = null;
                    let cardClass = cx(["card col-12 text-center"]);
                    let infoCardClass = cx(["card p-3 col-12 position-relative"]);
                    let cardHeadClass = cx([globalClasses.Font18MediumBlue])
                    let cardValueClass = cx(['mt-2', globalClasses.Font18MediumGrey])
                    let chartTitleClass = cx(['col-12 p-3', classes.ChartTitle])
                    let imageClass = cx(['position-absolute m-3', classes.ChartInfoImage])
                    if (!isEmpty(usersCount)) {
                        usersCountComponent = (
                            <div className={infoCardClass}>
                                <span className={cardHeadClass}>
                                    {REPORT_STRINGS.USERS.TOTAL_USERS}
                                </span>
                                <span className={cardValueClass}>
                                    {getWesternNumber(usersCount)}
                                </span>
                                <img
                                    className={imageClass}
                                    src={REPORT_USERS}
                                    alt={REPORT_STRINGS.USERS.LABEL}
                                    title={REPORT_STRINGS.USERS.LABEL}
                                />
                            </div>
                        )
                    }
                    if (!isEmpty(serviceAccounCount)) {
                        serviceAccountCountComponent = (
                            <div className={infoCardClass}>
                                <span className={cardHeadClass}>
                                    {REPORT_STRINGS.SERVICE_ACCOUNTS.TOTAL_SERVICE_ACCOUNTS}
                                </span>
                                <span className={cardValueClass}>
                                    {getWesternNumber(serviceAccounCount)}
                                </span>
                                <img
                                    className={imageClass}
                                    src={REPORT_ACCOUNTS}
                                    alt={REPORT_STRINGS.SERVICE_ACCOUNTS.LABEL}
                                    title={REPORT_STRINGS.SERVICE_ACCOUNTS.LABEL}
                                />
                            </div>
                        )
                    }
                    if (!isEmpty(totalConsumedCredits)) {
                        totalCreditComponent = (
                            <div className={infoCardClass}>
                                <span className={cardHeadClass}>
                                    {REPORT_STRINGS.CREDITS.TOTAL_CREDITS}
                                </span>
                                <span className={cardValueClass}>
                                    {totalConsumedCredits}
                                </span>
                                <img
                                    className={imageClass}
                                    src={REPORT_CREDITS}
                                    alt={REPORT_STRINGS.CREDITS.LABEL}
                                    title={REPORT_STRINGS.CREDITS.LABEL}
                                />
                            </div>
                        )
                    }
                    if (!isEmpty(totalTransactions)) {
                        totalTransactionComponent = (
                            <div className={infoCardClass}>
                                <span className={cardHeadClass}>
                                    {REPORT_STRINGS.TRANSACTIONS.TOTAL_TRANSACTIONS}
                                </span>
                                <span className={cardValueClass}>
                                    {getWesternNumber(totalTransactions)}
                                </span>
                                <img
                                    className={imageClass}
                                    src={REPORT_TRANSACTIONS}
                                    alt={REPORT_STRINGS.TRANSACTIONS.LABEL}
                                    title={REPORT_STRINGS.TRANSACTIONS.LABEL}
                                />
                            </div>
                        )
                    }
                    if (!isEmptyObject(tierByCreditData)) {
                        let chartTitle = (
                            <h3 className={chartTitleClass}>
                                {REPORT_STRINGS.TIER_CREDIT_TRANSACTION_CHART.CREDIT_TITLE}
                            </h3>
                        )
                        tierByCreditComponent = (
                            <div className={cardClass}>
                                {chartTitle}
                                <div className={'px-5'}>
                                    <PieChart
                                        labels={tierByCreditData.tierByCreditLabels}
                                        datasets={tierByCreditData.tierByCreditDataset}
                                        legendTitle={REPORT_STRINGS.TIER_CREDIT_TRANSACTION_CHART.LEGEND_TITLE}
                                        valueUnit={REPORT_STRINGS.TIER_CREDIT_TRANSACTION_CHART.VALUE_UNIT_CREDIT}
                                    />
                                </div>
                            </div>
                        )
                    }
                    if (!isEmptyObject(tierByTransactionData)) {
                        let chartTitle = (
                            <h3 className={chartTitleClass}>
                                {REPORT_STRINGS.TIER_CREDIT_TRANSACTION_CHART.TRANSACTION_TITLE}
                            </h3>
                        )
                        tierByTransactionComponent = (
                            <div className={cardClass}>
                                {chartTitle}
                                <div className={'px-5'}>
                                    <PieChart
                                        labels={tierByTransactionData.tierByTransactionLabels}
                                        datasets={tierByTransactionData.tierByTransactionDataset}
                                        legendTitle={REPORT_STRINGS.TIER_CREDIT_TRANSACTION_CHART.LEGEND_TITLE}
                                        valueUnit={REPORT_STRINGS.TIER_CREDIT_TRANSACTION_CHART.VALUE_UNIT_TRANSACTON}
                                    />
                                </div>
                            </div>
                        )
                    }
                    if (!isEmptyList(initialUserName)) {
                        let innerComponent = null;
                        let graphFilterComponent = null;
                        let chartTitle = (
                            <h3 className={chartTitleClass}>
                                {REPORT_STRINGS.USER_CREDIT_TRANSACTION_CHART.TITLE}
                            </h3>
                        )
                        graphFilterComponent = (
                            <div className="row px-4 pb-4">
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-2">
                                    <SelectField
                                        containerClass={"d-flex flex-column text-start"}
                                        label={REPORT_STRINGS.USER_CREDIT_TRANSACTION_CHART.USER_SELECT_TITLE}
                                        optionsList={[REPORT_STRINGS.ALL_VALUE, ...initialUserName]}
                                        value={searchedUserName}
                                        onChangeHandler={(value) => this.onChangeUsersCreditTransaction(value)}
                                        labelClass={globalClasses.FontRobotoMedium}
                                    />
                                </div>
                            </div>
                        )
                        if (userByCreditTransactionLoading) {
                            innerComponent = (
                                <Loader
                                    loaderStyle={globalClasses.LoaderMediumSize}
                                    containerClass={classes.ReportComponentLoader}
                                />
                            )
                        } else {
                            if (!isEmptyObject(userByCreditTransactionData)) {
                                innerComponent = (
                                    <div className={'p-3'}>
                                        <HybridChart
                                            // title={'Net credits and transactions consumed by users'}
                                            labels={userByCreditTransactionData.userByCreditTransactionLabels}
                                            datasets={userByCreditTransactionData.userByCreditTransactionDataset}
                                            xAxis={REPORT_STRINGS.USER_CREDIT_TRANSACTION_CHART.XAXIS_LABEL}
                                            yAxis={REPORT_STRINGS.USER_CREDIT_TRANSACTION_CHART.YAXIS_LABEL}
                                            screenWidth={screenWidth}
                                        />
                                    </div>
                                )
                            } else {
                                innerComponent = (
                                    <NoData
                                        mainText={REPORT_STRINGS.USER_CREDIT_TRANSACTION_CHART.NO_DATA_MAIN_TEXT}
                                        subText={REPORT_STRINGS.USER_CREDIT_TRANSACTION_CHART.NO_DATA_SUB_TEXT}
                                    />
                                )
                            }
                        }
                        userByCreditTransactionComponent = (
                            <div className={cardClass}>
                                {chartTitle}
                                {graphFilterComponent}
                                {innerComponent}
                            </div>
                        )
                    }
                    if (!isEmptyList(initialTaskNames)) {
                        let innerComponent = null;
                        let graphFilterComponent = null;
                        let chartTitle = (
                            <h3 className={chartTitleClass}>
                                {REPORT_STRINGS.MODEL_GROUP_CREDIT_TRANSACTION_CHART.TITLE}
                            </h3>
                        )
                        graphFilterComponent = (
                            <div className="row px-4 pb-4">
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-2">
                                    <SelectField
                                        containerClass={"w-100 text-start"}
                                        labelClass={globalClasses.FontRobotoMedium}
                                        label={REPORT_STRINGS.MODEL_GROUP_CREDIT_TRANSACTION_CHART.MODEL_GROUP_SELECT_TITLE}
                                        optionsList={[REPORT_STRINGS.ALL_VALUE, ...initialTaskNames]}
                                        value={searchedTaskName}
                                        onChangeHandler={(event) => this.onChangeTasksCreditTransaction(event)}
                                    />
                                </div>
                            </div>
                        )
                        if (taskByCreditTransactionLoading) {
                            innerComponent = (
                                <Loader
                                    loaderStyle={globalClasses.LoaderMediumSize}
                                    containerClass={classes.ReportComponentLoader}
                                />
                            )
                        } else {
                            if (!isEmptyObject(taskByCreditTransactionData)) {
                                innerComponent = (
                                    <div className={'p-3'}>
                                        <HybridChart
                                            // title={'Net credits and transactions consumed by model groups'}
                                            labels={taskByCreditTransactionData.taskByCreditTransactionLabels}
                                            datasets={taskByCreditTransactionData.taskByCreditTransactionDataset}
                                            xAxis={REPORT_STRINGS.MODEL_GROUP_CREDIT_TRANSACTION_CHART.XAXIS_LABEL}
                                            yAxis={REPORT_STRINGS.MODEL_GROUP_CREDIT_TRANSACTION_CHART.YAXIS_LABEL}
                                            screenWidth={screenWidth}
                                        />
                                    </div>
                                )
                            } else {
                                innerComponent = (
                                    <NoData
                                        mainText={REPORT_STRINGS.MODEL_GROUP_CREDIT_TRANSACTION_CHART.NO_DATA_MAIN_TEXT}
                                        subText={REPORT_STRINGS.MODEL_GROUP_CREDIT_TRANSACTION_CHART.NO_DATA_SUB_TEXT}
                                    />
                                )
                            }
                        }
                        taskByCreditTransactionComponent = (
                            <div className={cardClass}>
                                {chartTitle}
                                {graphFilterComponent}
                                {innerComponent}
                            </div>
                        )
                    }
                    if (!isEmptyList(initialModelNames)) {
                        let innerComponent = null;
                        let graphFilterComponent = null;
                        let chartTitle = (
                            <h3 className={chartTitleClass}>
                                {REPORT_STRINGS.MODEL_CREDIT_TRANSACTION_CHART.TITLE}
                            </h3>
                        )
                        graphFilterComponent = (
                            <div className="row px-4 pb-4">
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-2">
                                    <SelectField
                                        containerClass={"w-100 text-start"}
                                        labelClass={globalClasses.FontRobotoMedium}
                                        label={REPORT_STRINGS.MODEL_CREDIT_TRANSACTION_CHART.LIMIT_SELECT_TITLE}
                                        optionsList={[REPORT_STRINGS.ALL_VALUE, REPORT_STRINGS.TOP_VALUE, REPORT_STRINGS.BOTTOM_VALUE]}
                                        value={searchedModelLimit}
                                        onChangeHandler={(value) => this.onChangeModelsCreditTransaction(value, REPORT_STRINGS.ALL_VALUE)}

                                    />
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-2">
                                    <SelectField
                                        containerClass={"w-100 text-start"}
                                        labelClass={globalClasses.FontRobotoMedium}
                                        label={REPORT_STRINGS.MODEL_CREDIT_TRANSACTION_CHART.MODEL_SELECT_TITLE}
                                        optionsList={[REPORT_STRINGS.ALL_VALUE, ...initialModelNames]}
                                        value={searchedModelName}
                                        onChangeHandler={(value) => this.onChangeModelsCreditTransaction(searchedModelLimit, value)}

                                    />
                                </div>
                            </div>
                        )
                        if (modelByCreditTransactionLoading) {
                            innerComponent = (
                                <Loader
                                    loaderStyle={globalClasses.LoaderMediumSize}
                                    containerClass={classes.ReportComponentLoader}
                                />
                            )
                        } else {
                            if (!isEmptyObject(modelByCreditTransactionData)) {
                                innerComponent = (
                                    <div className={'p-3'}>
                                        <HybridChart
                                            // title={'Net credits and transactions consumed by models'}
                                            labels={modelByCreditTransactionData.modelByCreditTransactionLabels}
                                            datasets={modelByCreditTransactionData.modelByCreditTransactionDataset}
                                            xAxis={REPORT_STRINGS.MODEL_CREDIT_TRANSACTION_CHART.XAXIS_LABEL}
                                            yAxis={REPORT_STRINGS.MODEL_CREDIT_TRANSACTION_CHART.YAXIS_LABEL}
                                            screenWidth={screenWidth}
                                        />
                                    </div>

                                )
                            } else {
                                innerComponent = (
                                    <NoData
                                        mainText={REPORT_STRINGS.MODEL_CREDIT_TRANSACTION_CHART.NO_DATA_MAIN_TEXT}
                                        subText={REPORT_STRINGS.MODEL_CREDIT_TRANSACTION_CHART.NO_DATA_SUB_TEXT}
                                    />
                                )
                            }
                        }
                        modelByCreditTransactionComponent = (
                            <div className={cardClass}>
                                {chartTitle}
                                {graphFilterComponent}
                                {innerComponent}
                            </div>
                        )
                    }
                    if (!isEmptyList(initialTiers) || !isEmptyList(initialModelNames)
                        || !isEmptyList(initialTaskNames) || !isEmptyList(initialUserName)) {
                        let innerComponent = null;
                        let graphFilterComponent = null;
                        let chartTitle = (
                            <h3 className={chartTitleClass}>
                                {REPORT_STRINGS.TREND_CREDIT_TRANSACTION_CHART.TITLE}
                            </h3>
                        )
                        graphFilterComponent = (
                            <div className="row px-4 pb-4">
                                {
                                    !isEmptyList(initialTiers) &&
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-2">
                                        <SelectField
                                            label={REPORT_STRINGS.TREND_CREDIT_TRANSACTION_CHART.TIER_SELECT_TITLE}
                                            optionsList={[REPORT_STRINGS.ALL_VALUE, ...initialTiers]}
                                            value={searchedTier}
                                            containerClass={'w-100 text-start'}
                                            labelClass={globalClasses.FontRobotoMedium}
                                            onChangeHandler={(value) => this.onChangeDailyTrend(value, searchedTrendTaskName, searchedTrendModelName, searchedTrendUserName)}
                                        />
                                    </div>
                                }
                                {
                                    !isEmptyList(initialTaskNames) &&
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-2">
                                        <SelectField
                                            label={REPORT_STRINGS.TREND_CREDIT_TRANSACTION_CHART.MODEL_GROUP_SELECT_TITLE}
                                            optionsList={[REPORT_STRINGS.ALL_VALUE, ...initialTaskNames]}
                                            value={searchedTrendTaskName}
                                            onChangeHandler={(event) => this.onChangeDailyTrend(searchedTier, event, searchedTrendModelName, searchedTrendUserName)}
                                            containerClass={'w-100 text-start'}
                                            labelClass={globalClasses.FontRobotoMedium}
                                        />
                                    </div>
                                }
                                {
                                    !isEmptyList(initialModelNames) &&
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-2">
                                        <SelectField
                                            label={REPORT_STRINGS.TREND_CREDIT_TRANSACTION_CHART.MODEL_SELECT_TITLE}
                                            optionsList={[REPORT_STRINGS.ALL_VALUE, ...initialModelNames]}
                                            value={searchedTrendModelName}
                                            onChangeHandler={(event) => this.onChangeDailyTrend(searchedTier, searchedTrendTaskName, event, searchedTrendUserName)}
                                            containerClass={'w-100 text-start'}
                                            labelClass={globalClasses.FontRobotoMedium}
                                        />
                                    </div>
                                }
                                {
                                    !isEmptyList(initialUserName) &&
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mt-2">
                                        <SelectField
                                            containerClass={"w-100 text-start"}
                                            labelClass={globalClasses.FontRobotoMedium}
                                            label={REPORT_STRINGS.TREND_CREDIT_TRANSACTION_CHART.USER_SELECT_TITLE}
                                            optionsList={[REPORT_STRINGS.ALL_VALUE, ...initialUserName]}
                                            value={searchedTrendUserName}
                                            onChangeHandler={(event) => this.onChangeDailyTrend(searchedTier, searchedTrendTaskName, searchedTrendModelName, event)}
                                        />
                                    </div>
                                }
                            </div>
                        )
                        if (dailyTrendLoading) {
                            innerComponent = (
                                <Loader
                                    loaderStyle={globalClasses.LoaderMediumSize}
                                    containerClass={classes.ReportComponentLoader}
                                />
                            )
                        } else {
                            if (!isEmptyObject(dailyTrendData)) {
                                innerComponent = (
                                    <div className={'p-3'}>
                                        <HybridChart
                                            // title={'Net credits and transactions consumed trend'}
                                            labels={dailyTrendData.dailyTrendByCreditTransactionLabels}
                                            datasets={dailyTrendData.dailyTrendByCreditTransactionDataset}
                                            xAxis={REPORT_STRINGS.TREND_CREDIT_TRANSACTION_CHART.XAXIS_LABEL}
                                            yAxis={REPORT_STRINGS.TREND_CREDIT_TRANSACTION_CHART.YAXIS_LABEL}
                                            screenWidth={screenWidth}
                                            legendPositionRight={true}
                                        />
                                    </div>
                                )
                            } else {
                                innerComponent = (
                                    <NoData
                                        mainText={REPORT_STRINGS.TREND_CREDIT_TRANSACTION_CHART.NO_DATA_MAIN_TEXT}
                                        subText={REPORT_STRINGS.TREND_CREDIT_TRANSACTION_CHART.NO_DATA_SUB_TEXT}
                                    />
                                )
                            }
                        }
                        dailyTrendComponent = (
                            <div className={cardClass}>
                                {chartTitle}
                                {graphFilterComponent}
                                {innerComponent}
                            </div>
                        )
                    }
                    if (!isEmpty(usersCountComponent)
                        || !isEmpty(serviceAccountCountComponent)
                        || !isEmpty(totalCreditComponent)
                        || !isEmpty(totalTransactionComponent)
                        || !isEmpty(tierByCreditComponent)
                        || !isEmpty(tierByTransactionComponent)
                        || !isEmpty(userByCreditTransactionComponent)
                        || !isEmpty(taskByCreditTransactionComponent)
                        || !isEmpty(modelByCreditTransactionComponent)
                        || !isEmpty(dailyTrendComponent)
                    ) {
                        reportDataContainer = (
                            <div className="my-2 my-md-4" ref={this.reportRef}>
                                {
                                    !isAdvancedFilterEnabled &&
                                    <div className="col-12 text-start mb-3">
                                        <span className={cx(['ms-1', globalClasses.Font16Grey])}>
                                            {/* <MdInfoOutline style={{marginBottom:'2px'}}/>  */}
                                            You are viewing the data from {fromDate} to {toDate}
                                        </span>
                                    </div>
                                }
                                <div className="row px-2 mb-3">
                                    <div className="col-12 col-sm-6 col-lg-3 p-2">
                                        {usersCountComponent}
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-3 p-2">
                                        {serviceAccountCountComponent}
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-3 p-2">
                                        {totalCreditComponent}
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-3 p-2">
                                        {totalTransactionComponent}
                                    </div>
                                </div>
                                <div className="col-12 px-1 mb-3">
                                    {userByCreditTransactionComponent}
                                </div>
                                <div className="row px-2 mb-3">
                                    <div className="col-12 col-md-6 p-2">
                                        {tierByCreditComponent}
                                    </div>
                                    <div className="col-12 col-md-6 p-2">
                                        {tierByTransactionComponent}
                                    </div>
                                </div>
                                <div className="col-12 px-1 mb-3">
                                    {taskByCreditTransactionComponent}
                                </div>
                                <div className="col-12 px-1 mb-3">
                                    {modelByCreditTransactionComponent}
                                </div>
                                <div className="col-12 px-1 mb-3">
                                    {dailyTrendComponent}
                                </div>
                            </div>
                        )
                    } else {
                        reportDataContainer = (
                            <NoData
                                mainText={"No Report Data Found"}
                                subText={"We can't find any data for this account"}
                            />
                        )
                    }
                } else {
                    if (isDetailedViewReportLoading) {
                        reportDataContainer = <Loader
                            // loaderStyle={globalClasses.LoaderMediumSize}
                            containerClass={classes.DetailedReportComponentLoader}

                        />
                    } else {
                        if (isDetailedViewReportError) {
                            reportDataContainer = <ErrorPage />
                        }
                        else if (isEmptyList(detailedReportData)) {
                            reportDataContainer = <NoData
                                mainText={"No Report Data Found"}
                                subText={"We can't find any data"}
                            />
                        }
                        else {
                            // closeDetailedView = <div className={cx(['text-end mt-3', classes.Pointer])} onClick={this.onClickDetailedReportView}>
                            //     <span className={globalClasses.Font16Orange}>{"Close"}</span>
                            // </div>

                            reportDataContainer = (
                                <div className="col-12 my-2 my-md-4">

                                <div className="row flex-wrap mb-5 justify-content-center">
                                {
                                REPORT_STRINGS.IDP_PRODUCTS.map((item, index) => (
                                    <Button
                                    key={index}
                                    id={item.ID}
                                    containerClass={"col-4 col-md-3 col-xl-2"}
                                    className={cx("col-12",classes.configuratorButtonTab)}
                                    label = {item.PRODUCT}
                                    onClickHandler = {() => {this.setSelectedProductType(item.ID)}}
                                    actionType={this.state.selectedProduct === item.ID ? BUTTON_TYPES.ACTIONS.PRIMARY : BUTTON_TYPES.ACTIONS.SECONDARY} 
                                    radiusType={BUTTON_TYPES.RADIUS.PILL} 
                                    borderType={BUTTON_TYPES.BORDER.BORDERED}
                                />
                                    ))
                            }
                        </div>  

                                    {
                                        !isAdvancedFilterEnabled &&
                                        <div className="col-12 text-start mb-3">
                                            <span className={cx(['ms-1', globalClasses.Font16Grey])}>
                                                {/* <MdInfoOutline style={{marginBottom:'2px'}}/>  */}
                                                You are viewing the data from {fromDate} to {toDate}
                                            </span>
                                        </div>
                                    }
                                    <SortingTable
                                        headingList={[{
                                            key: 'transactionId',
                                            label: 'Transaction ID',
                                            isDate: false
                                        }, 
                                        {
                                            key: 'fileId',
                                            label: 'File ID',
                                            isDate: false,
                                            class: globalClasses.TableCell

                                        },{
                                            key: 'leafGroup',
                                            label: 'Leaf Product',
                                            isDate: false
                                        },{
                                            key: 'modelCode',
                                            label: 'Model Code',
                                            isDate: false
                                        }, {
                                            key: 'modelName',
                                            label: 'Model Name',
                                            isDate: false
                                        }, {
                                            key: 'tier',
                                            label: 'Model Tier',
                                            isDate: false,
                                            hide : true
                                        },{
                                            key: 'inputToken',
                                            label: 'Input Tokens',
                                            isDate: false,
                                            isNumber:true,
                                            hide : true,
                                            renderItem : (item) => {
                                                if(isEmpty(item.inputToken)){
                                                    return 'NA'
                                                }
                                                return item.inputToken
                                            }
                                        },{
                                            key: 'outputToken',
                                            label: 'Output Tokens',
                                            isDate: false,
                                            isNumber:true,
                                            hide : true,
                                            renderItem : (item) => {
                                                if(isEmpty(item.outputToken)){
                                                    return 'NA'
                                                }
                                                return item.outputToken
                                            }
                                        },{
                                            key: 'credit',
                                            label: 'Consumed',
                                            isDate: false,
                                            isNumber:true,
                                            renderItem : (item) => {
                                                let unit = " Page(s)"
                                                if(item?.leafGroup === SUBSCRIPTION_PRODUCTS.LEAF_IDP.NAME){
                                                    unit = " Page(s)"
                                                }
                                                if(item?.leafGroup === SUBSCRIPTION_PRODUCTS.LEAF_CONVERSE.NAME){
                                                    unit = " USD"
                                                }
                                                if(item?.leafGroup === SUBSCRIPTION_PRODUCTS.LEAF_ESSENTIALS.NAME){
                                                    unit = " USD"
                                                }
                                                if(isEmpty(item.credit) && (item.credit!==0)){
                                                    return '-'
                                                }
                                                return <span title={item.credit+unit}>{Number(item.credit)}&nbsp;{unit}</span>
                                            }
                                        },
                                        {
                                            key: 'userName',
                                            label: 'User/API',
                                            isDate: false
                                        },  {
                                            key: 'transactionDate',
                                            label: 'Transaction Date',
                                            isDate: true,
                                            renderItem : (item) => {
                                                return getTableTimeData(item.transactionDate)
                                            }
                                        }]}
                                        enableExports={true}
                                        exportTableName={[this.props.accountUUID,"Report"].join(SEPARATORS.UNDERSCORE)}
                                        exportKeysList={
                                            [
                                                {
                                                    key : "transactionId",
                                                    label : "Transaction ID"
                                                },
                                                {
                                                    key : "leafGroup",
                                                    label : "Leaf Product"
                                                },
                                                {
                                                    key : "modelCode",
                                                    label : "Model Code"
                                                },
                                                {
                                                    key : "modelName",
                                                    label : "Model Name"
                                                },{
                                                    key: 'credit',
                                                    label: 'Consumed',
                                                    getValue : (item) => {
                                                        let unit = " Page(s)"
                                                        if(item?.leafGroup === SUBSCRIPTION_PRODUCTS.LEAF_IDP.NAME){
                                                            unit = " Page(s)"
                                                        }
                                                        if(item?.leafGroup === SUBSCRIPTION_PRODUCTS.LEAF_CONVERSE.NAME){
                                                            unit = " USD"
                                                        }
                                                        if(item?.leafGroup === SUBSCRIPTION_PRODUCTS.LEAF_ESSENTIALS.NAME){
                                                            unit = " USD"
                                                        }
                                                        if(isEmpty(item.credit) && (item.credit!==0)){
                                                            return '-'
                                                        }
                                                        return item?.credit+unit
                                                    }
                                                },
                                                {
                                                    key: 'userName',
                                                    label: 'User/API'
                                                }, {
                                                    key: 'transactionDate',
                                                    label: 'Transaction Date'
                                                }
                                            ]
                                        }
                                        enableSearch={true}
                                        searchKeysList={
                                            [
                                                {
                                                    key : "userName",
                                                    label : "User Name",
                                                    value : "User Name",
                                                    placeholder : "Search by User Name"
                                                },
                                                {
                                                    key : "modelName",
                                                    label : "Model Name",
                                                    value : "Model Name",
                                                    placeholder : "Search by Model Name"
                                                },
                                                {
                                                    key : "modelCode",
                                                    label : "Model Code",
                                                    value : "Model Code",
                                                    placeholder : "Search by Model Code"
                                                }
                                            ]
                                        }
                                        // dataList={detailedReportData}
                                        dataList={detailedReportData.filter(item => item.leafGroup === (REPORT_STRINGS.IDP_PRODUCTS.find(product => product.ID === this.state.selectedProduct)).PRODUCT)}
                                        itemsToShow={SORT_TABLE_VALUES.ITEM_COUNTS.DETAILED_REPORT}
                                        noteText={SORT_TABLE_VALUES.TABLE_NOTES.DATE_FORMAT2}
                                    />
                                </div>
                            )
                        }

                    }

                }
            }
            return (
                <>
                    {loaderComponent}
                    {filterComponentsContainer}
                    {showDetailedViewBtn}
                    <div className={cx("my-3",globalClasses.HorizontalLine)}/>
                    {/* {closeDetailedView} */}
                    {reportDataContainer}
                </>
            )
        }
    }
}

export default ReportsPane;
